import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Grid from '@material-ui/core/Grid';
import CafeThijssenLogo from '../../images/brands/cafethijssen-logo.png';
import ReturnArrow from '../../images/return-arrow.svg';

const Cafethijssen = () => (
  <Layout>
    <Grid container
          className="link-list"
    >
      <Grid item xs={4}>
        <div>
          <Link to="/projects/"><img src={ ReturnArrow } className="return-arrow" width='30px' alt="Return Logo" /></Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/about/">About</Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/contact/">Contact</Link>
        </div>
      </Grid>
    </Grid>
    <Grid container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={8}>
        <div className='app-intro'>
          <Link to="projects/">Projects</Link>
          <Grid item xs={12}>
                 <span>
              <Link className="arrow" to="/projects/defysioman">&#x2039; </Link>
            </span>
            <h2 className="inline"> Cafe Thijssen </h2>
            <span >
              <Link className="arrow" to="/projects/actionpact">&#x203A;</Link>
            </span>
          </Grid>
          <img src={ CafeThijssenLogo } className="brand-detail"  alt="Skills" />
          <Grid container
                direction="row"
                justify="center"
                className="about-content">
            <Grid item md={6}>
              <h3>Summary</h3>
              <p>Implement a new website for Cafe thijssen in Amsterdam. CMS based, so they can edit it on their own. </p>
            </Grid>
            <Grid item md={6}>
              <h3>Stack</h3>
              <p className="subcontent">Wordpress, Css, Javascript, PHP and Photoshop.</p>
              <h3>Period</h3>
              <p className="subcontent">2015</p>
              <h3>URL</h3>
              <a className="white" href='http://cafethijssen.nl' rel="noopener noreferrer"  target="_blank" alt="cafethijseen"> <h4>cafethijssen.nl </h4></a>

            </Grid>
          </Grid>
        </div>
      </Grid>
  </Layout>
)

export default Cafethijssen;
